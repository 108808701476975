import React, { useEffect } from "react";
import {Routes, Route, Outlet, useLocation } from 'react-router-dom';
// Headers
import HeaderGlobal from "./partials/headerGlobal";
import FooterGlobal from "./partials/footerGlobal";
import HeaderUsuario from "./partials/headerUsuario";
import FooterUsuarioAdmin from "./partials/footerUsuarioAdmin";
import HeaderAdmin from "./partials/headerAdmin";
import HeaderCamarografo from "./partials/headerCamarografo";
// páginas privadas
import Registro from "./paginas/registrar";
import InicioSesion from "./paginas/Iniciosesion";
import InicioSesionAdmin from "./paginas/inicioSesionAdmin";
import InicioSesionCamarografo from "./paginas/inicioSesionCamarografio";
import Alerta from "./paginas/alerta";
import AlertaConDatos from "./paginas/alertaConDatos";
import AlertaAntesDeGrabar from "./paginas/alertAntesDeGrabar";
// paginas GLOGAL
import Home from "./paginas/webGlobal/home";
import Cookies from "./paginas/webGlobal/cookies";
import Terminos from "./paginas/webGlobal/terminos";
import Privacidad from "./paginas/webGlobal/politicaPrivacidad";
import Contacto from "./paginas/webGlobal/contacto";
import Faq from "./paginas/webGlobal/faq";
import VerTorneos from "./paginas/webGlobal/verTorneos";
import VerTorneoIndividual from "./paginas/webGlobal/verTorneoIndividual";
import VerPartidoIndividual from "./paginas/webGlobal/verPartido";
import VideoIndividualGlobal from "./paginas/webGlobal/videoIndividualGlobal";
import VerFecha from "./paginas/webGlobal/verFecha";
import PagaYgraba from "./paginas/webGlobal/pagaYgraba";
import PagaYgrabaBoton from "./paginas/pagaYgrabaBoton";
import AnaliticaPatrocinadores from "./paginas/webGlobal/analiticaPatrocinadores";
import VerVideosGlobal from "./paginas/webGlobal/verVideosGlobal";
import PasosEliminarCuenta from "./paginas/webGlobal/pasosEliminarCuenta";
// Usuario
import HomeUsuario from "./paginas/usuario/homeusuario";
import Micuentausuario from "./paginas/usuario/miCuentaUsuario";
import VideoIndividual from "./paginas/usuario/videoIndividual";
import VerTorneosUsuario from "./paginas/usuario/verTorneosUsuario";
import VerTorneoIndividualUsuario from "./paginas/usuario/verTorneoIndividualUsuario";
import VerPartidoIndividualUsuario from "./paginas/usuario/verPartidoUsuario";
import VerFechaUsuario from "./paginas/usuario/verFechaUsuario";
import Pagos from "./paginas/usuario/pagos";
import MisPartidosGrabar from "./paginas/usuario/misPartidosGrabar";
import EditarVideos from "./paginas/usuario/editarVideos";
import MisVideos from "./paginas/usuario/misVideos";
// Administrador
import HomeAdmin from "./paginas/administrador/homeAdministrador";
import Micuenta from "./paginas/administrador/miCuenta";
import CrearEqipo from "./paginas/administrador/crearEquipo";
import CrearTorneo from "./paginas/administrador/crearTorneo";
import CrearEstadio from "./paginas/administrador/crearEstadio";
import EditarTorneo from "./paginas/administrador/editarTorneo";
import CrearPatrocinador from "./paginas/administrador/crearPatrocinador";
import EditarPatrocinador from "./paginas/administrador/editarPatrocinador";
// Camarógrafo
import HomeCamarografo from "./paginas/camarografo/homeCamarografo";
import Grabar from "./paginas/camarografo/grabar";
import Grabando from "./paginas/camarografo/grabando";
import VerVideos from "./paginas/camarografo/verVideos";
import PruebaCamara from "./paginas/camarografo/pruebaCamara";
import AnadirVoz from "./paginas/camarografo/anadirVoz";
import TodosUsuarios from "./paginas/administrador/todosUsuarios";
import VerUsuario from "./paginas/administrador/verUsuario";
import RecuperarContrasena from "./paginas/recuperarContrasena";
import PartidoEncontrado from "./paginas/camarografo/partidoEncontrado";
import CompletarProcesos from "./paginas/camarografo/completarProcesos";
import GrabarVozPorSolicitud from "./paginas/camarografo/grabarVozPorSolicitud";
import TorneosGrabar from "./paginas/camarografo/torneosGrabar";
import TorneoIndividualGrabar from "./paginas/camarografo/torneoIndividualGrabar";
// MERCADOPAGO
import AlertaMercadoPago from "./paginas/mercadoPago/alertaMercadoPago";

function Direccionamiento(){

    const location = useLocation();

   // Función para guardar la URL anterior en localStorage
    useEffect(() => {
        const urlActual = location.pathname + location.search; // capturamos la url actual

        const urlACTUALalmacenada = localStorage.getItem('urlActual'); // obtenemos la url actual que ya está almacenada en localStorage

        // Si la url almacenada es diferente a la actual, actualizamos la url anterior
        if (urlActual !== urlACTUALalmacenada) {
            // Movemos la url actual almacenada a 'urlAnterior'
            if (urlACTUALalmacenada) {
                localStorage.setItem('urlAnterior', urlACTUALalmacenada); // la actual almacenada se convierte en la anterior
            }
            // Almacenamos la nueva url actual
            localStorage.setItem('urlActual', urlActual);
        }
    }, [location]); // Se ejecuta cada vez que la ubicación cambie

    /* Outleet se modifica con respecto a la ruta, añadiendo paga y graba en solo global */
    function PlantillaGlobal() {

        return (
            <div>
                <HeaderGlobal />
                    <Outlet />
                    {/* <PagaYgrabaBoton />  */}
                <FooterGlobal />
            </div>
        )
    }

    function PlantillaUsuario() {
        return (
            <div>
                <HeaderUsuario />
                    <Outlet />
                <FooterUsuarioAdmin />
            </div>
        )
    }

    function PlantillaAdministrador() {
        return (
            <div>
                <HeaderAdmin />
                    <Outlet />
                <FooterUsuarioAdmin />
            </div>
        )
    }

    function PlantillaCamarografo() {
        return (
            <div>
                <HeaderCamarografo />
                    <Outlet />
                <FooterUsuarioAdmin />
            </div>
        )
    }
    

    
    return (
        // Web Global
        <Routes>
            <Route path="/registrar" element={<Registro />} />
            <Route path="/iniciar-sesion" element={<InicioSesion />} />
            <Route path="/iniciar-sesion-administrador" element={<InicioSesionAdmin />} />
            <Route path="/iniciar-sesion-camarografo" element={<InicioSesionCamarografo />} />
            <Route path="/recuperar-contrasena" element={<RecuperarContrasena />} />
            <Route path="/alerta" element={<Alerta />} />
            <Route path="/alerta-datos" element={<AlertaConDatos />} />
            <Route path="/alerta-para-grabar" element={<AlertaAntesDeGrabar />} />
            <Route  path="grabando-ios/:username/:desdeDonde/:datosPartido" element={<Grabando />}/>  
            <Route  path="prueba" element={<PruebaCamara />}/> 
            <Route path="/:tipoAlerta/:username/" element={<AlertaMercadoPago />} />
            
            {/* Rutas Global */}
            <Route path="/" element={<PlantillaGlobal />}>
                <Route index element={<Home />}/>
                <Route  path="cookies" element={<Cookies />}/>
                <Route  path="terminos" element={<Terminos />}/>
                <Route  path="privacidad" element={<Privacidad />}/>
                <Route  path="contacto" element={<Contacto />}/>
                <Route  path="faq" element={<Faq />}/>
                <Route  path="ver-analitica" element={<AnaliticaPatrocinadores />}/>
                <Route  path="paga-y-graba" element={<PagaYgraba />}/>
                <Route path="/ver-videos/:deporte" element={<VerTorneos />} />
                <Route path="/ver-torneo/:nombreTorneo" element={<VerTorneoIndividual />} />
                <Route path="/ver-torneo-por-fecha/:nombreTorneo/:fecha" element={<VerFecha />} />
                <Route path="/ver-partido/:idPartido/:nombrePartido" element={<VerPartidoIndividual />} />
                <Route path="/video/:idVideo" element={<VideoIndividualGlobal />} />
                <Route  path="/videos" element={<VerVideosGlobal />}/>
                <Route  path="/pasos-eliminar-cuenta" element={<PasosEliminarCuenta />}/>
            </Route>
            {/* Rutas Usuario */}
            <Route path="/cliente" element={<PlantillaUsuario />}>
                <Route  path="home/:username" element={<HomeUsuario />}/>
                <Route  path="mi-cuenta/:username" element={<Micuentausuario />}/>
                <Route  path="videos/:deporte" element={<VerTorneosUsuario />} />
                <Route path="torneo/:nombreTorneo" element={<VerTorneoIndividualUsuario/>} />
                <Route path="torneo-por-fecha/:nombreTorneo/:fecha" element={<VerFechaUsuario />} />
                <Route path="partido/:idPartido/:nombrePartido" element={<VerPartidoIndividualUsuario />} />
                <Route  path="video/:idvideo" element={<VideoIndividual />}/>
                <Route  path="pagos/:username" element={<Pagos />}/>
                <Route  path="partidos/:username" element={<MisPartidosGrabar />}/>
                <Route  path="editar-videos/:username/:pagina" element={<EditarVideos />}/>
                <Route  path="mis-videos/:username/:pagina" element={<MisVideos />}/>
            </Route>
            {/* Rutas Administrador */}
            <Route path="/admin" element={<PlantillaAdministrador />}>
                <Route  path="home/:username" element={<HomeAdmin />}/> 
                <Route  path="mi-cuenta/:username" element={<Micuenta />}/>
                <Route  path="crear-equipo/:username/:pagina" element={<CrearEqipo />}/>
                <Route  path="crear-torneo/:username/:pagina" element={<CrearTorneo />}/>
                <Route  path="crear-patrocinador/:username/:pagina" element={<CrearPatrocinador />}/>
                <Route  path="crear-estadio/:username/:pagina" element={<CrearEstadio />}/>
                <Route  path="editar-torneo/:username" element={<EditarTorneo />}/>
                <Route  path="editar-patrocinador/:username" element={<EditarPatrocinador />}/>
                <Route  path="usuarios/:username/:pagina" element={<TodosUsuarios />}/>
                <Route  path="ver-usuario/:username/:idUsuario" element={<VerUsuario />}/>
            </Route>
            {/* Rutas Camarógrafo */}
            <Route path="/camarografo" element={<PlantillaCamarografo />}>
                <Route  path="home/:username" element={<HomeCamarografo />}/> 
                <Route  path="grabar/:username/:pagina" element={<TorneosGrabar />}/> 
                <Route  path="grabar/:username/torneo/:idTorneo" element={<TorneoIndividualGrabar />}/> 
                <Route  path="grabar/:username/fecha/:numeroFecha/torneo/:idTorneo" element={<Grabar />}/>
                <Route  path="ver-videos-partido/:username/:pagina" element={<VerVideos />}/> 
                <Route  path="anadir-voz/:username" element={<AnadirVoz />}/> 
                <Route  path="ver-partido-encontrado/:username" element={<PartidoEncontrado />}/> 
                <Route  path="completar-procesos/:username" element={<CompletarProcesos />}/> 
                <Route  path="grabar-voz-por-solicitud/:username" element={<GrabarVozPorSolicitud />}/> 
            </Route>
        </Routes>
    );   
}


export default Direccionamiento;