import React, {useState, useEffect} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function HeaderCamarografo(){
    const navigate = useNavigate();
    const paramsUsuario = useParams(); // obtenemos params para poner en url
    const [infoUsuarioLocal, setInfoLocal] = useState(null); // Variables localstorage

    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

    }, [navigate]);

    
    
    function handleLogOut(event){
        // evita el parpadeo predefinido
        event.preventDefault();
        localStorage.clear(); // limpiamos el almacentaiento local.

        axios.post("/logout")
        .then(function(response){
            console.log(response);
            navigate("/");
        });
    }

    return(
        <div>
            <nav id="miNavBar" className="navbar navbar-expand-sm navbar-dark" aria-label="Third navbar example" >
                <div className="container-fluid">
                    {/* Imagen */}
                    <a className="navbar-brand" href={"/camarografo/home/" + paramsUsuario.username } ><img src="/imagenes/marca/clvhorizontalblanco.png" alt="" height="40px" /></a>

                    {/* Botón adaptable */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    
                    <div className="collapse navbar-collapse" id="navbarsExample04">
                        {/* Menú principal */}
                        {(infoUsuarioLocal) 
                        ? <ul className="navbar-nav me-auto mb-2 mb-sm-0">
                            {/* Mostrar home a todos, menos a narrador  */}
                            {(infoUsuarioLocal.usuario.tipo!=="Narrador") ? <li className="nav-item"><a className="nav-link text-light" href={"/camarografo/home/" + paramsUsuario.username }>Home</a></li> : null} 
                            {/* Mostrar Grabar a camarografo y camarografoEditor  */}
                            {(infoUsuarioLocal.usuario.tipo!=="Narrador") ? <li className="nav-item"><a className="nav-link text-light" href={"/camarografo/grabar/" + paramsUsuario.username + "/1" }>Grabar</a></li> : null} 
                            {/* Mostrar completar procesos a camarografoEditor solamente  */}
                            {(infoUsuarioLocal.usuario.tipo==="CamarografoEditor") ? <li className="nav-item"><a className="nav-link text-light" href={"/camarografo/completar-procesos/" + paramsUsuario.username }>Completar procesos</a></li> : null} 
                            {/* Mostrar grabar voz a narrador y a camarografoEditor  */}
                            {(infoUsuarioLocal.usuario.tipo!=="Camarografo") ? <li className="nav-item"><a className="nav-link text-light" href={"/camarografo/grabar-voz-por-solicitud/" + paramsUsuario.username }>Grabar voz</a></li> : null} 
                        </ul>

                        : null
                        }


                        {/* Segunda parte del menú */}
                        <div className="text-md-end text-center headerFinal">
                            {/* <div>
                                <a href={"/admin/mi-cuenta/" + paramsUsuario.username } className="btn btn-outline-w me-2" role="button" >Mi cuenta</a>
                            </div> */}
                            <div>
                                <form>
                                {/* <a href="/logout" className="btn btn-primary" role="button" >Cerrar Sesión</a> */}
                                <button onClick={handleLogOut} className="btn btn-primary-w" type="submit">Cerrar Sesión</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
    
}

export default HeaderCamarografo;