import '../../estilos/paginas.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import RegistrarParaVer from './registrarParaVer';
 

function VerPartidoIndividual() {

  const navigate    = useNavigate();
  const location    = useLocation(); // cuando no se envía nada en state, location,state es null
  const routeParams = useParams();
  let { idPartido, nombrePartido, username}   = routeParams; // capturamos para paginación
  const [patrocinador, setPatrocinador] = useState(null);
  const [videos, setVideos] = useState(null);
  const [partido, setPartido] = useState(null);
  const [partidos, setPartidos] = useState(null);
  const [cargando, setCargando]  = useState(true);
  const [siguientePagina, setSiguientePagina] = useState(null);
  const [paginaAnterior, setPaginaAnterior] = useState(null); 
  const [cantidadDePaginas, setCantidadDePaginas] = useState(null);
  const [paginaActual, setPaginaActual] = useState(() => {
    return parseInt(localStorage.getItem("paginaActual")) || 1;
  });


  
  useEffect(() => {

    async function obtenerDeportes(){
      await axios.post("/obtener-partido-global", { 
        params: {nombrePartido: nombrePartido, idPartido: idPartido, desde: "global", pagina: paginaActual }
      })
      .then(async function(response){
        console.log(response);
        if(response.status===200){ 
          setCargando(false); // quitamos la espera
          setVideos(response.data.videosPaginacion); 
          setPartido(response.data.infoParido);
          setCantidadDePaginas(response.data.cantidadDePaginas);
          setSiguientePagina(response.data.siguientePagina);
          setPaginaAnterior(response.data.paginaAnterior);
          setPaginaActual(response.data.paginaActual);
          setPatrocinador(response.data.patrocinador);
        }
        else{
          setCargando(false); // quitamos la espera
        }
      });
    }
    obtenerDeportes();


    return () => {
      localStorage.setItem("paginaActual", 1);
    }
  }, []);

  
  async function atras(){
    // actualizar la p}agina actual
    let siguiente = paginaActual - 1; 
    localStorage.setItem("paginaActual", siguiente);
    window.location.reload();
  }

  async function adelante(){
    // actualizar la pagina actual
    let siguiente = paginaActual + 1; 
    localStorage.setItem("paginaActual", siguiente);
    window.location.reload();
    //console.log( "que imprime" + siguiente);
  }
 
  function handleVideoClick(idVideo){
  let urlAverVideo = "/video/" + idVideo; 

  // Navegar y forzar la recarga
  navigate(urlAverVideo, { replace: true });
  window.location.reload();  // Esto forzará la recarga completa de la página
  };


  
  return(
    <div className=''>
      

      <div className="d-flex mx-3 flex-column flex-sm-row">

      {/* Columna 1: Banner patrocinador (Igual a la columna 3) */}
      <div className="col-12 col-sm-3 sticky-container-01 px-5">
        <div className="sticky-content-01">
        {patrocinador && patrocinador.banners[0].urlBanner !== "" ? (
        <div>
            <h3 className="text-center mb-3">Patrocinado por</h3>
            <a href={patrocinador.banners[0].urlRedireccion} target="_blank" rel="noopener noreferrer" className="link-container-01">
            <img 
                            className="img-fluid-01 mostrar-en-pequenas mb-5" 
                            src={patrocinador.logo} 
                            alt="Banner Patrocinador" 
                            key={patrocinador.logo + Date.now()} 
                        />
                        <img 
                            className="img-fluid-full-01 ocultar-en-pequenas mb-5" 
                            src={`${patrocinador.banners[0].urlBanner}?t=${Date.now()}`} 
                            alt="Banner Patrocinador" 
                            key={patrocinador.banners[0].urlBanner + Date.now()} 
                        />
            </a>
        </div>
        ) : (
        <img className="img-fluid-01 mb-3" src="/imagenes/marca/clvcazul.png" alt="Banner Predeterminado" />
        )}
        </div>
      </div>

      {/* Columna 2: Video */}
      <div className="col-12 col-sm-6 container-fluid">
      {/* Video Contenedor */}
            {/* Información del partido */}
            {(partido && partido.length > 0)
              ? <div id="" className="container bg-body-tertiary p-5 my-4 rounded-3" >
              <div className="d-flex justify-content-center">
                  <div className="row">
                      <div className="col-md-6 text-center">
                          <img className="imagenPartido mb-5" alt="" style={{ display: "block", margin: "auto" }} src={partido[0].imagenEquipoUno}></img>
                      </div>
                      <div className="col-md-6 text-center">
                          <img className="imagenPartido mb-5" alt="" style={{ display: "block", margin: "auto" }} src={partido[0].imagenEquipoDos}></img>
                      </div>
                  </div>
              </div>
              <h2 className=' text-center'>{partido[0].nombreEquipoUno + " vs " + partido[0].nombreEquipoDos}</h2>
              <p  className=' text-center'>{partido[0].torneo}</p>
              <p  className=' text-center'>{partido[0].nombreEstadio}</p>
            </div>
              : null
            }
            {/* Videos */}
            <div className="container mt-5"> 


              <div id="contenedorVideos" className="row">
                {(videos && videos.length > 0) ? (
                videos.map((videoActual, index) =>
                  videoActual.urlVideo !== "NA" ? (
                  <div key={index} className="col-md-3 mb-5 d-flex flex-column align-items-center" >
                      
                    <div className="contenedor-video-grabacion position-relative" onClick={() => handleVideoClick(videoActual.video.idVideo)}
                    >
                        {/* Envolver el video en un Link para redirigir */}
                        <video
                        id={`video-${index}`}
                        className="video-js vjs-default-skin videoEnvVerVideos"
                        preload="auto"
                        poster={(videoActual.video.urlImagen) ? videoActual.video.urlImagen : ""}
                        data-setup="{ddd}"
                        style={{ width: '100%' }}
                        >
                        <source
                            src={`${videoActual.video.urlVideo}?v=${new Date().getTime()}`}
                            type="video/MP4"
                        />
                        </video>
                        
                        {/* Ícono de Play superpuesto */}
                        <div className="overlay-play-icon">
                            <i className="bi bi-play-circle iconoReproducir" ></i>
                        </div>
                    </div>

                    {/* Descripción del video */}
                    <div className="d-flex justify-content-center align-items-center my-2">
                        <p className="textosDescripcionUno text-center me-2 mb-0"><strong>{videoActual.video.equipo}</strong></p>
                        <p className="textosDescripcionUno text-center me-2 mb-0">{videoActual.video.tipo}</p>
                        
                        <div className="d-flex align-items-center me-2">
                            <i className="textosDescripcionUno bi bi-heart me-1"></i>
                            <p className="textosDescripcionUno text-center mb-0">{videoActual.video.meGusta.length}</p>
                        </div>
                        
                        <div className="d-flex align-items-center me-2">
                            <i className="textosDescripcionUno bi bi-play me-1"></i>
                            <p className="textosDescripcionUno text-center mb-0">{videoActual.video.vistas}</p>
                        </div>
                    </div>
                  </div>
                  ) : null
                )
                ) : (
                <p>Buscando partido.</p>
                )}
              </div>


              {/* Botones paginación */}
              <div className="row mb-3">
                  <div className="col-6"> <button className={   "w-100 my-3  btn btn-primary"  + (paginaAnterior < 1 ? " disabled" : "")    }  onClick={atras} disabled={paginaAnterior < 1} > Atrás  </button> </div>
                  <div className="col-6"> <button className={   "w-100 my-3  btn btn-primary"  + (siguientePagina > cantidadDePaginas ? " disabled" : "")    }  onClick={adelante} disabled={siguientePagina > cantidadDePaginas} > Adelante  </button> </div>
              </div>

            </div>
      </div>

      {/* Columna 3: Banner patrocinador (Igual a la columna 1) */}
      <div className="col-12 col-sm-3 sticky-container-01 px-5">
      <div className="sticky-content-01">
      {patrocinador && patrocinador.banners[0].urlBanner !== "" ? (
      <div>
      <h3 className="text-center mb-3">Patrocinado por</h3>
      <a href={patrocinador.banners[0].urlRedireccion} target="_blank" rel="noopener noreferrer" className="link-container-01">
      <img 
                            className="img-fluid-01 ocultar-en-pequenas mb-5" 
                            src={patrocinador.logo} 
                            alt="Banner Patrocinador" 
                            key={patrocinador.logo + Date.now()} 
                        />
                        <img 
                            className="img-fluid-full-01 mostrar-en-pequenas mb-5" 
                            src={`${patrocinador.banners[0].urlBanner}?t=${Date.now()}`} 
                            alt="Banner Patrocinador" 
                            key={patrocinador.banners[0].urlBanner + Date.now()} 
                        />
      </a>
      </div>
      ) : (
      <img className="img-fluid-01 mb-3" src="/imagenes/marca/clvcazul.png" alt="Banner Predeterminado" />
      )}
      </div>
      </div>

      </div>



      <RegistrarParaVer />

      
      {/* zona cargando */}
      {cargando && (
          <div className="overlay">
          <div className="loader"></div>
          <p className="loadingText">Buscando torneo, por favor espere...</p>
          </div>
      )}

    </div>
  )
}



export default VerPartidoIndividual;