import '../../estilos/paginas.css';
import { useEffect } from 'react';



function PasosEliminarCuenta() {
  // Modificar la clase de body par página de registro - styles.css /////////
  useEffect(() => {
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  // Modificar la clase de body par página de registro - styles.css /////////
 
    
  return(
    <div className='contenedorPaginasUsuario'>
      
      

        {/* Primer contenedor: imagen principal */}
        <div className='contenedorTerminos'>
            <img className='logoContendorPrincipal' src='/imagenes/marca/comolaviob.png' alt=''></img>
            <p className='subContenedorPrincipal'>Eliminar mi cuenta</p>
        </div>

        {/* contenido */}
        <div className='container my-5'>
          <div class="p-5 mb-4 bg-body-tertiary rounded-3">
              <div class="container-fluid py-5">
                <h1 class="display-5 fw-bold">Eliminar mi cuenta</h1>
                <p class="col-md-12">
                  Si deseas eliminar tu cuenta de "COMO LA VIO", aquí te explicamos los pasos que debes seguir:  
                  <ol className='mt-3'>
                    <li>Inicia sesión en tu cuenta.</li>
                    <li>Dirígete a la sección "Información de usuario".</li>
                    <li>En la parte inferior de esa sección, encontrarás el botón para eliminar tu cuenta. Haz clic en él.</li>
                  </ol>
                  Ten en cuenta que una vez ejecutada esta acción no podremos volver a darte acceso a tu uenta, ya que esta será eliminada de nuestra base de datos.
                  Este paso elimina toda la información referente a tu cuenta incluyendo: correo, telefono, nombre, videos que te gustan, puntos en la aplicación.
                  <br />
                </p>


                <img style={{width: "100%"}} src='/imagenes/webGlobal/botoneliminar.png'></img>
                <img className='mb-5' style={{width: "100%"}} src='/imagenes/webGlobal/modaleliminar.png'></img>

                <strong >Es importante que estés seguro de tu decisión, ya que este proceso es irreversible.</strong>
              </div>
          </div>
        </div>
    </div>
  )
}



export default PasosEliminarCuenta;