import '../../estilos/paginas.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";

 

function AnaliticaPatrocinadores() {


    const [torneos, setTorneos] = useState(null);
    const [cargando, setCargando]  = useState(true);
    const [torneoSeleccionado, setTorneoSeleccionado] = useState(null);
    const [cantidadUsuarios, setCantidadUsuarios]     = useState(null);
    const [likesTotales, setLikesTotales]             = useState(null);
    const [vistasTotales, setVistasTotales]           = useState(null);
    const [partidosAusar, setPartidosAusar]           = useState(null);
    const [fechasAusar, setFechasAusar]               = useState(null);
    const [equipoSelec, setEquipoSelec]               = useState(null);
    const [equipoSolo, setEquipoSolo]                 = useState(null); 
    const [equipoAbuscar, setEquipoAbuscar]           = useState (null);
    const [disableAllButtons, setDisableAllButtons]   = useState(false); // Estado para deshabilitar todos los botones
    const [disableAllDropdowns, setDisableAllDropdowns] = useState(false); // Estado para deshabilitar todos los dropdowns
    const [mostrarBotonLimpiar, setMostrarBotonLimpiar] = useState(false); // Nuevo estado
    const [disableEquipoSolo, setDisableEquipoSolo] = useState(false); 


    const[inputDos, setInputDos] = useState ({
        torneo: null,
        fecha: null,
        equipo: null
    
    });
    



    useEffect(() => {


    async function obtenerTorneos(){
        await axios.post("/obtener-torneos", {
            params: {deporte: "futbol"} // se enviar el params o parametro de busqueda la backend
        })
        .then(async function(response){
            if(response.status===200){ 
            setCargando(false);
            setTorneos(response.data.torneos);
            }
        });
    }
    obtenerTorneos();

    async function obtenerEquipos() {
        await axios.post("/obtener-equipos-solos", {
            

        })
        .then(async function(response){
            if(response.status===200){ 
            setCargando(false);
            setEquipoSolo(response.data.equipoSolo);
            }
        }); 
    }
    obtenerEquipos();

    
    

    // Modificar la clase de body par página de registro - styles.css /////////
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
    }, []);
  
    


    

    

    function handleChange3(event){ setEquipoAbuscar(event.target.value); };

    
    function handleChange2(event) {
        const { name, value } = event.target;

        // Validar el valor antes de actualizar el estado
        setInputDos(prevInputDos => {
            return {
                ...prevInputDos,
                [name]: value
            };
        });
    }


    

    async function escogerTorneo(event){
        event.preventDefault();
        let idTorneo = inputDos.torneo;
        if(!idTorneo){ alert("Debes seleccionar un torneo"); }
        else{ 
            setCargando(true);  
            //1. CREAR PARTIDO COMPLETO
            await axios.post("/obtener-todo-torneo", {
                headers: { 'Content-Type': 'multipart/form-data',},
                params: {  idTorneo: idTorneo}
            })
            .then(function(response){  
                setCargando(false);
                if(response.status===200){ 
                    setTorneoSeleccionado(response.data.torneo);
                    setCantidadUsuarios(response.data.cantidadUsuarios);
                    setLikesTotales(response.data.likesTotales);
                    setVistasTotales(response.data.vistasTotales);
                    setPartidosAusar(response.data.partidosAusar); 
                    // guardar las fechas disponibles
                    setFechasAusar(response.data.torneo.cantidadDeFechas);
                    setEquipoSelec(response.data.equipos);
                    setDisableEquipoSolo(true);
                }  
                else{window.location.reload(); alert("Error. Por favor volver a intentar");}
            });
            setMostrarBotonLimpiar(true);
            
        }
        


        
    }


    async function buscarPartidosPorFecha(event){
        
        event.preventDefault();
        let idTorneo = inputDos.torneo;
        let fechaSeleccionada = inputDos.fecha;
        

        if(!fechaSeleccionada){ alert("Debes seleccionar una fecha"); }
        else{ 
            setCargando(true);

            axios.post("/obtener-partidos-por-fechas", {
                params:{idTorneo: idTorneo, fechaSeleccionada: fechaSeleccionada, partidosDeTorneo: partidosAusar}
            })
            .then(function(response){
                setCargando(false);
                if(response.data.status===200){
                    setPartidosAusar(response.data.partidos);
                }
                else{
                    alert("Error al buscar.");
                }
            });
            disableAll(); // Deshabilitar todos los dropdowns después de seleccionar las fechas
            setMostrarBotonLimpiar(true);
        }

    }

    async function buscarEquiposPorTorneo(event){
        
        event.preventDefault();
        let idTorneo = inputDos.torneo;
        let equipoSeleccionado = inputDos.equipo;

        if(!equipoSeleccionado){ alert("Debes seleccionar un equipo"); }
        else{ 
            setCargando(true);
            axios.post("/obtener-equipos-por-torneo", {
                params:{idTorneo: idTorneo, equipoSeleccionado: equipoSeleccionado, equiposDelTorneo: equipoSelec , partidosDeTorneo: partidosAusar}
            })
            .then(function(response){
                setCargando(false);
                if(response.data.status===200){
                    setPartidosAusar(response.data.partidosDelEquipo);
                }
                else{
                    alert("Error al buscar.");
                }
                
                    
                }
                );
                disableAll(); 
                setMostrarBotonLimpiar(true);
            }
    }

    const disableAll = () => {
        setDisableAllDropdowns(true); // Deshabilitar dropdowns
        setDisableAllButtons(true); // Deshabilitar botones
    };

    const limpiarInputs = () => {
        setInputDos({ torneo: '', fecha: '', equipo: '' });
        setDisableAllDropdowns(false); // Reactivar dropdowns
        setDisableAllButtons(false); // Reactivar botones
        setMostrarBotonLimpiar(false); // Ocultar el botón "Limpiar y Recargar"
        setDisableEquipoSolo(false);
        window.location.reload(); // Recarga la página
    };


    async function buscarEquipo(event){
        setCargando(true);
        event.preventDefault();
        
        let idEquipo = equipoAbuscar;

        
        
        if(!idEquipo){
            setCargando(false);
            alert("Debes seleccionar un equipo");
            
        }
        else{
            axios.post("/obtener-partidos-equipos", {
            params:{ idEquipo: idEquipo } })
            .then(function(response){
                setCargando(false);
                if(response.data.status===200){
                    setPartidosAusar(response.data.partidosAusar);
                }
                else{  alert(response.data.mensaje);  }    
            });
            disableAll(); // Deshabilitar todos los dropdowns después de seleccionar las fechas
            setMostrarBotonLimpiar(true); // Mostrar el botón "Limpiar y Recargar"
        }
    }

    
  return(
    <div className='contenedorPaginasUsuario'>
      
        {/* Primer contenedor: imagen principal */}
        <div className='contenedorANALITICA text-light'>
            <h1>Analítica</h1>
            <p className='subContenedorPrincipal'>Conoce aquí el estado de Cómo la vio</p>
        </div>


        {/* contenedor seleccionar torneo */}
        <div className='container my-5'>
            <div className="p-sm-5 p-3 mb-4 bg-dark rounded-3">
                <div className="container-fluid py-5">
                    
                        <h1 className="display-5 fw-bold text-light">Selecciona torneo o equipo</h1>
                        <p className="col-md-8 fs-4 text-light mb-5">Escoge un torneo o equipo para ver la analítica del torneo.</p>

                        <p className='text-light'>1 Escoge torneo</p>
                    
                        {/* Dropdown torneo */}
                        {(torneos) 
                        ?   <form>
                            <div className="form-floating mb-2">
                            <select 
                            onChange={handleChange2} 
                            className="form-select" 
                            id="torneo" 
                            name="torneo" 
                            value={inputDos.torneo} 
                            aria-label="Seleccione un torneo" 
                            disabled={disableAllDropdowns} // Deshabilitar cuando sea necesario
                            >
                                <option value="">Seleccionar torneo</option>
                                    {torneos.map(torneo => (
                                <option key={torneo.idTorneo} value={torneo.idTorneo}>{torneo.nombre}</option>
                                ))}
                            </select>
                            <label htmlFor="torneo">Torneos</label>
                            </div>

                            {/* Botón escoger */}
                            <button 
                                type="submit" 
                                className="btn btn-warning w-100" 
                                onClick={escogerTorneo} 
                                disabled={disableAllButtons}
                                >
                                Escoger torneo
                                </button>
                            </form>
                        : null}

                        {/* Dropdown fechas y equipos en una fila */}
                        {(fechasAusar !== 0 && fechasAusar !== null) || (equipoSelec !== 0 && equipoSelec !== null) ? (
                        <div className="row mt-3">
                            <p className='text-light'>Selecciona la fecha o el equipo que quieres ver de este torneo</p>

                            {/* Formulario de Fecha */}
                            {fechasAusar !== 0 && fechasAusar !== null && (
                                <div className="col-md-6">
                                    <form>
                                        <div className="form-floating mb-2">
                                            <select
                                                onChange={handleChange2}
                                                className="form-select"
                                                id="fecha"
                                                name="fecha"
                                                value={inputDos.fecha}
                                                aria-label="Seleccione una fecha"
                                                disabled={disableAllDropdowns}
                                            >
                                                <option value="">Seleccionar fechas</option>
                                                {Array.from({ length: fechasAusar }, (_, index) => index + 1).map((fechaActual) => (
                                                    <option key={fechaActual} value={fechaActual}> 
                                                        Fecha {fechaActual}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="fecha">Fecha</label>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-warning w-100"
                                            onClick={buscarPartidosPorFecha}
                                            disabled={disableAllButtons}
                                        >
                                            Escoger fechas
                                        </button>
                                    </form>
                                </div>
                            )}

                            {/* Formulario de Equipo */}
                            {equipoSelec !== 0 && equipoSelec !== null && (
                                <div className="col-md-6">
                                    <form>
                                        <div className="form-floating mb-2">
                                            <select
                                                onChange={handleChange2}
                                                className="form-select"
                                                id="equipo"
                                                name="equipo"
                                                value={inputDos.equipo}
                                                aria-label="Seleccione un Equipo"
                                                disabled={disableAllDropdowns}
                                            >
                                                <option value="">Seleccionar un equipo</option>
                                                {equipoSelec.map((equipo) => (
                                                    <option key={equipo.idEquipo} value={equipo.nombre} > 
                                                        {equipo.nombre}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="equipo">Equipo</label>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-warning w-100"
                                            onClick={buscarEquiposPorTorneo}
                                            disabled={disableAllButtons}
                                        >
                                            Escoger equipos
                                        </button>
                                    </form>
                                </div>
                                
                            )}
                        
                        </div>
                        ) : null}

                        
                    
                        <p className='text-light mt-5'>2 Escoge equipo</p>
                        {/* Dropdown EquipoSolo */}
                        {equipoSolo ? (
                            <div>
                                <form>
                                    <div className="form-floating my-2">
                                        <select 
                                            onChange={handleChange3} 
                                            className="form-select" 
                                            id="equipoAbuscar" 
                                            name="equipoAbuscar" 
                                            value={equipoAbuscar} 
                                            aria-label="Seleccione un equipo" 
                                            disabled={disableEquipoSolo || disableAllDropdowns} // Deshabilitar cuando sea necesario
                                        >
                                            <option value="">Seleccionar equipo</option>
                                            {equipoSolo.map(equipoSolo => (
                                                <option key={equipoSolo.idEquipo} value={equipoSolo.idEquipo}>
                                                    {equipoSolo.nombre}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="equipoAbuscar">Equipo</label>
                                    </div>

                                    {/* Botón escoger */}
                                    <button 
                                        type="button" 
                                        className="btn btn-warning w-100" 
                                        onClick={buscarEquipo} 
                                        disabled={disableEquipoSolo || disableAllButtons} // Deshabilitar cuando sea necesario
                                    >
                                        Escoger Equipo
                                    </button>
                                </form>

                                
                            </div>
                        ) : null}

                        

                        {/* Botón limpiar */}
                        {mostrarBotonLimpiar && (
                            <div className="mt-4 text-center">
                                <button className="btn btn-danger w-100" onClick={limpiarInputs}>
                                    Limpiar y Recargar
                                </button>
                            </div>
                        )}


            </div>
        </div>




        {/* Contendeor torneo seleccionado */}
        <div>
        
            {/* INFORMACIÓN DEL TORNEO */}
            {(torneoSeleccionado) 
            ? <div className='container'>
                <div className="p-5 my-4 bg-body-tertiary rounded-3">
                    {/* FILA PRINCIPAL */}
                    <div className="row  d-flex align-items-center" style={{ minHeight: "400px" }}>
                    {/* Imagen */}
                    <div className="col-lg-6 d-flex justify-content-center">
                            <div className="d-flex justify-content-center"> <img className="imagenPrincipal" src={torneoSeleccionado.imagen}  alt="" /></div>
                    </div>

                    {/* Nombre y texto */}
                    <div className="col-lg-6 d-flex justify-content-center">
                        <div className="container-fluid py-5">
                        <h1 className="display-5 fw-bold">Torneo {torneoSeleccionado.nombre}</h1>
                        <p className="col-md-8 fs-4">Deporte: {torneoSeleccionado.deporte}</p>
                            
                        {(torneoSeleccionado.video && torneoSeleccionado.video!=="")
                        ? <video
                            className="video-js vjs-default-skin videoIndividual"
                            preload="auto"
                            controls
                            data-setup="{}"
                            style={{ height: "200px"}}
                            onError={(e) => console.error("Error al cargar el video:", e)}
                            >
                            <source src={torneoSeleccionado.video} type="video/mp4" />
                        </video>
                        : null
                        }
                        </div>
                    </div>

                    <p className="">{torneoSeleccionado.descripcion}</p>
                    </div>

                    {/* FILA REPRODUCCIONES, LIKES Y CANTIDAD DE USUARIOS */}
                    <div className="d-flex flex-wrap justify-content-around align-items-center my-3">
                        {/* Vistas */}
                        <div className="d-flex align-items-center p-3 bordeAnaliticas mb-3 flex-column flex-sm-row">
                            <p className="textosDescripcionTorneoAnalitica text-center mb-0 me-1">Vistas totales</p>
                            <i className="textosDescripcionTorneoAnalitica bi bi-play me-1"></i>
                            <p className="textosDescripcionTorneoAnalitica text-center mb-0">{vistasTotales}</p>
                        </div>
                        
                        {/* Likes totales */}
                        <div className="d-flex align-items-center p-3 bordeAnaliticas mb-3 flex-column flex-sm-row">
                            <p className="textosDescripcionTorneoAnalitica text-center mb-0 me-1">Likes</p>
                            <i style={{ color: "#3A58A5" }} className="textosDescripcionTorneoAnalitica bi bi-heart-fill me-1"></i>
                            <p className="textosDescripcionTorneoAnalitica text-center mb-0">{likesTotales}</p>
                        </div>
                        
                        {/* Usuarios totales */}
                        <div className="d-flex align-items-center p-3 bordeAnaliticas flex-column flex-sm-row">
                            <p className="textosDescripcionTorneoAnalitica text-center mb-0 me-1">Usuarios</p>
                            <i style={{ color: "#3A58A5" }} className="textosDescripcionTorneoAnalitica bi bi-person-circle me-1"></i>
                            <p className="textosDescripcionTorneoAnalitica text-center mb-0">{cantidadUsuarios}</p>
                        </div>
                    </div>
                </div>
            </div>
            :null}



            {/* INFORMACIÓN TODOS LOS PARTIDOS */}
            {(torneoSeleccionado || partidosAusar) 
            ? <div className='container'>
                <div className="p-5 my-4 bg-body-tertiary rounded-3">
                    {/* Contenedor partidos individuales */}
                    {partidosAusar
                    .sort((a, b) => b.vistasPorPartido - a.vistasPorPartido) // Ordenar por vistas de mayor a menor
                    .map( (actual, key) => (
                        <div key={actual.partido.idPartido} className="row gx-3 gy-2 align-items-center mb-1 py-3">

        
                            {/* id */}
                            <div className="col-sm-1">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.partido.idPartido}  />
                                    <label htmlFor="floatingInputGrid">id</label>
                                </div>
                            </div>


                            {/* Partido */}
                            <div className="col-sm-5">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.equipoUno.nombre + " vs " + actual.equipoDos.nombre}  />
                                    <label htmlFor="floatingInputGrid">Partido</label>
                                </div>
                            </div>

                            {/* videos por partido */}
                            <div className="col-sm-1">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.cantidadVideos}  />
                                    <label htmlFor="floatingInputGrid">Video</label>
                                </div>
                            </div>

                            {/* likes por partido */}
                            <div className="col-sm-1">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.likesPorPartido}  />
                                    <label htmlFor="floatingInputGrid">Likes</label>
                                </div>
                            </div>


                            {/* Vistas por partido */}
                            <div className="col-sm-1">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.vistasPorPartido}  />
                                    <label htmlFor="floatingInputGrid">Vista</label>
                                </div>
                            </div>

                          
                           

                            {/* Video más viral */}
                            <div className="col-sm-2">
                                {actual.videoMasViral ? (
                                    actual.videoMasViral.urlVideo !== "NA" ? (
                                        <a 
                                            className={`btn btn-primary w-100 ${actual.videoMasViral === null ? 'disabled' : ''}`} 
                                            href={"video/" + actual.videoMasViral.idVideo} 
                                            // href={actual.videoMasViral.urlVideo} 
                                            role="button" 
                                            target='_blank' 
                                            rel="noreferrer"
                                            onClick={e => {
                                                if (actual.videoMasViral.urlVideo === "NA" || actual.videoMasViral === null) {
                                                    e.preventDefault(); // Prevenir la acción predeterminada
                                                }
                                            }}
                                        >
                                            Ver video
                                        </a>
                                    ) : null
                                ) : null}
                            </div>
                            

                            

                        </div>
                    
                    ))}
                    
                </div>
            </div>
            :null}

        </div> 
        








        {/* zona cargando */}
        {cargando && (
            <div className="overlay">
            <div className="loader"></div>
            <p className="loadingText">Completando proceso, por favor espere...</p>
            </div>
        )}



    </div>
    </div>
  )
}



export default AnaliticaPatrocinadores;