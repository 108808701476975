import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';

function EliminarCuenta(props) {

    const navigate = useNavigate();

    const { urlVolver, usuario } = props; // contiene la url para volver: /cliente/mi-cuenta/eduardo2
    const [show, setShow] = useState(false); // mostrar modal
    const handleClose = () => setShow(false); // cerrar modal
    const handleShow = () => setShow(true); // mostrar modal

    
    async function eliminarCuenta(){
        try{
            await axios.post("/eliminar-cuenta", {
                params: {username: usuario}
            }).then(async function(response){
                if(response.status === 200){
                    alert("Cuenta eliminada correctamente.");
                    navigate("/");
                }
                else{
                    alert("Error al eliminar la cuenta");
                }
            })
        }
        catch(err){
            console.log(err);
            alert("Error al eliminar la cuenta.");
        }
    }


    console.log(usuario);


    

    return(
        <div>
            <Button  variant="btn btn-light w-100 mt-3" onClick={handleShow}>
                Eliminar cuenta
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar cuenta</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <p>Es importante que sepas que esta acción es irreversible. Una vez hagas click tu cuenta será eliminada, incluyendo todos tus datos:.</p>
                    <ol>
                        <li>Nombre y apellidos</li>
                        <li>Dirección de correo electrónico</li>
                        <li>Usuario</li>
                        <li>Puntos acumulados</li>
                        <li>Teléfono</li>
                        <li>Identificación</li>
                    </ol>
                    
                    <button type="submit" className="btn btn-primary w-100" onClick={eliminarCuenta} >Eliminar cuenta</button>

                    
                </Modal.Body>

                {/* Footer */}
                <Modal.Footer>
                    <Button className="w-100" variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default EliminarCuenta;