import React, {useState, useEffect} from "react";
import axios from "axios";
import BotonMensajeGrabar from "./botonMensajeGrabar";
import RegistrarParaVer from "./webGlobal/registrarParaVer";
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

function ContenedorVideoIndividual(datos) {

console.log("datos: ", datos);

    const navigate         = useNavigate();
    let videoMostrar       = datos.videoMostrar; // reparar datos
    let patrocinador       = datos.patrocinador; 
    let idVideo            = datos.idVideo;
    let idCLV              = datos.idCLV;
    let urlVolver          = datos.urlVolver; 
    let videoPatrocinador  = datos.videoPatrocinador;
    let posicionVideoPatro = datos.posicionVideoPatro;

    const [existeMeGusta, setExisteMeGusta] = useState(null);
    const [meGustaCount, setMeGustaCount]   = useState(videoMostrar.video.meGusta.length - 1);
    const [etiquetas, setEtiquetas]         = useState(null);
    const [mostrarModal, setMostrarModal]   = useState(null);
    const [mostrarOmitir, setMostrarOmitir] = useState(false); // Estado para mostrar el botón de omitir
    const [contador, setContador] = useState(5); // Contador de 5 segundos
    // modal:
    const [show, setShow] = useState(false); // mostrar modal
    const handleClose = () => setShow(false); // cerrar modal
    const handleShow = () => setShow(true); // mostrar modal


    const [mostrarVideoPartido, setMostrarVideoPartido] = useState(false); // Estado para controlar cuándo mostrar el video del partido
    // Función para manejar el final del video del patrocinador
    const handlePatrocinadorEnd = () => {
        setMostrarVideoPartido(true); // Al finalizar el video del patrocinador, mostramos el video del partido
    };


   
    // console.log("videoPatrocinador:", videoPatrocinador);
    // console.log("posicion: ", posicionVideoPatro);

    useEffect(() => {
        async function buscarMeGustaUsuario() {
            await axios.post("/buscar-megusta", {
                params: { idVideo: idVideo, idCLV: idCLV}
            })
            .then(async function(response){
                if(response.data.status===200){ 
                    if(response.data.existeMegusta){setExisteMeGusta(true);}
                    else{setExisteMeGusta(false);}
                   
                }
                else{ console.log("error en el proceso");  }
            });
        }
        buscarMeGustaUsuario();

        async function obtenerEtiquetas(){
            await axios.post("/obtener-etiquetas-individual", {
                params: {idVideo: idVideo}
            })
            .then(async function(response){
                if(response.data.status ===200){
                    setEtiquetas(response.data.etiquetas);
                }
            })
        }
        obtenerEtiquetas();

        let intervalo;

        if (patrocinador && patrocinador.urlVideo && !mostrarVideoPartido) {
            // Comienza un contador de 5 segundos
            intervalo = setInterval(() => {
                setContador((prev) => {
                    if (prev > 0) return prev - 1;
                    return 0;
                });
            }, 1000);

            // Mostrar el botón "Omitir" después de 5 segundos
            const timeout = setTimeout(() => setMostrarOmitir(true), 5000);

            return () => {
                clearInterval(intervalo); // Limpiar intervalo
                clearTimeout(timeout); // Limpiar timeout
            };
        }

    }, [patrocinador, mostrarVideoPartido]);

    async function meGusta(){
        
        async function actualizarLikes(){
            await axios.post("/actualizar-likes", {
                params: { idVideo: idVideo, idCLV: idCLV}
            })
            .then(async function(response){
                if(response.data.status===200){ 
                    if(response.data.existeMeGusta === false){ // false porque no existía el me gusta, pero ahora si.
                        console.log("Me gusta añadido correctamente");
                        setExisteMeGusta(true);
                        setMeGustaCount(prev => prev + 1);  // Incrementar el contador
                    }
                    else{ console.log("este usuario ya dio me gusta a este video");  }
                }
                else{
                    console.log(response.data.mensaje);
                    alert(response.data.mensaje);
                }
            });
        }
        actualizarLikes();
    }

   //console.log(urlVolver);

    // Función para compartir
    async function compartirVideo(){

        let urlVideoCompartir = "";
        const tituloVideo = `${videoMostrar.nombreEquipoUno} vs ${videoMostrar.nombreEquipoDos} - ${videoMostrar.torneo}`;

        if(datos.idCLV===-1){  urlVideoCompartir = "/video/" + datos.idVideo;  }
        else{ urlVideoCompartir = "/cliente/video/" + datos.idVideo; }

        if (navigator.share) {
        navigator.share({
            title: tituloVideo,
            text: 'Mira este video increíble!',
            url: urlVideoCompartir
        })
        .then(async () => {
            console.log('Video compartido con éxito')

            // verificar si es usuario logueado o no
            if(idCLV>=0){
                // Ejecutamos aumento de puntos
                await axios.post("/aumentar-puntos", {
                    params: { idVideo: idVideo, idCLV: idCLV}
                })
                .then(async function(response){
                    if(response.data.status===200){ 
                    console.log("puntos aumentados");
                    }
                    else{ console.log("error en el proceso");  }
                });
            }
        })
        .catch((error) => console.error('Error al compartir:', error));
        } else {
        alert('La función de compartir no está disponible en este navegador');
        }
    };
    

    async function meGustaRegistrar(){
        handleShow();
        return;
    }

    const handleOmitirPublicidad = () => {
        setMostrarVideoPartido(true); // Cambiar al video principal
    };

    

    return(
       
    <div className="mb-3">
        <div id="contenedorVideos" className="row">
            <div className="contenedor-video-grabacion position-relative">

                


                {/* Video de patrocinador */}
                {videoPatrocinador && !mostrarVideoPartido && (
                    <div style={{ position: 'relative' }}>
                        <video
                            className="video-js vjs-default-skin videoIndividual"
                            preload="auto"
                            controls
                            autoPlay
                            // muted
                            playsInline
                            style={{ width: '100%' }}
                            onEnded={handlePatrocinadorEnd} // Ejecutar cuando el video del patrocinador termine
                        >
                            <source
                                src={videoPatrocinador.urlVideo}
                                type="video/mp4"
                            />
                        </video>


                        {/* si el video es largo, msotramos el boton omitir */}
                        { (posicionVideoPatro===0) ? 
                        <div>
                            {/* Botón para omitir publicidad */}
                            {mostrarOmitir && (
                                <button
                                    onClick={handleOmitirPublicidad}
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        padding: '10px 15px',
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        color: '#fff',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        zIndex: 10,
                                    }}
                                >
                                    Omitir publicidad
                                </button>
                            )}

                            {/* Contador */}
                            {!mostrarOmitir && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        left: '10px',
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        color: '#fff',
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        fontSize: '14px',
                                        zIndex: 10,
                                    }}
                                >
                                    Omitir en {contador}...
                                </div>
                            )}
                        </div>
                        : null 
                        }
                    </div>
                )}





            {/* Video de partido */}
            {(mostrarVideoPartido || !videoPatrocinador?.urlVideo) && (
                <video
                    id={videoMostrar.video.idVideo}
                    className="video-js vjs-default-skin videoIndividual"
                    preload="auto"
                    controls
                    autoPlay
                    // muted
                    playsInline
                    poster={videoMostrar.video.urlImagen}
                    style={{ width: '100%' }}
                >
                    <source
                        src={videoMostrar.video.urlVideo}
                        type="video/mp4"
                    />
                </video>
            )}

















                {idCLV === -1 
                ? <RegistrarParaVer />
                :null}
                
                
               

                {/* Descripción del video */}
                <div className="d-flex justify-content-center align-items-center my-2">
                    <p className="textosDescripcionUno text-center me-2 mb-0"><strong>{videoMostrar.video.equipo}</strong></p>
                    <p className="textosDescripcionUno text-center me-2 mb-0">{videoMostrar.video.tipo}</p>
                    {/* Me gusta */}

                    {/* Si no está loegado, el id es -1 */}
                    {(idCLV===-1)
                    ? <div className="d-flex align-items-center me-2">
                    {/* MODAAAL AQUI */}
                        <button onClick={meGustaRegistrar} className="btn p-0">
                            <i className="textosDescripcionUno bi bi-heart me-1"></i>
                        </button>
                        <p className="textosDescripcionUno text-center mb-0">{meGustaCount}</p>
                    </div>
                    : <div className="d-flex align-items-center me-2">
                    {existeMeGusta ? (
                        <i style={{ color: "#3A58A5" }} className="textosDescripcionUno bi bi-heart-fill me-1"></i>
                    ) : (
                        <button onClick={meGusta} className="btn p-0">
                        <i className="textosDescripcionUno bi bi-heart me-1"></i>
                        </button>
                    )}
                    <p className="textosDescripcionUno text-center mb-0">{meGustaCount}</p>
                    </div>
                    }

                    
                    

                    

                    
                    <div className="d-flex align-items-center me-2">
                        <i className="textosDescripcionUno bi bi-play me-1"></i>
                        <p className="textosDescripcionUno text-center mb-0">{videoMostrar.video.vistas}</p>
                    </div>
                </div>
                {/* Descripción dos del video */}
                <p className="textosDescripcionDos text-center me-2 mb-0">{videoMostrar.nombreEquipoUno + " vs " + videoMostrar.nombreEquipoDos}</p>
                <p className="textosDescripcionDos text-center me-2 mb-0">{videoMostrar.torneo}</p>
                <p className="textosDescripcionDos text-center me-2 mb-0">{videoMostrar.nombreEstadio}</p>

                {/* Etiquetas */}
                <p className="textosDescripcionDos text-center mt-4">Etiquetas</p>
                {(etiquetas) ? (
                    <div className="etiquetas-container">
                        {etiquetas.map((actual, key) => (
                            <p className="etiqueta-item" key={key}>{actual.nombreEtiqueta}</p>
                        ))}
                    </div>
                ) : null}


                {/* botón enviar a grabar */}
                {(idCLV===-1) ? null : <div> <BotonMensajeGrabar idCLV={idCLV} idVideo={idVideo} /></div>}


                {/* Botón atrás */}
                <a 
                className={`btn btn-warning w-100 mt-3 ${urlVolver === false ? 'disabled' : ''}`} 
                href={urlVolver !== false ? urlVolver : '#'}
                onClick={(e) => urlVolver === false && e.preventDefault()}
                >
                Atrás
                </a>

                {/* Modal para cuando no está logueado */}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Iniciar Sesión</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Por favor, inicia sesión para poder dar "me gusta" a este video.</p>
                        <Button variant="primary w-100" onClick={() => navigate("/iniciar-sesion")}>
                            Iniciar Sesión
                        </Button>
                        <Button variant="dark w-100 mt-1" onClick={() => navigate("/registrar")} >
                            Registrar
                        </Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                       
                    </Modal.Footer>
                </Modal>

                 {/* Botón compartir */}             
                 <button className="btn btn-success w-100 mt-3" onClick={compartirVideo}>
                Compartir Video
                </button>


                {/* Boton de link de video patrocinador */}
                {/* {(patrocinador && patrocinador.urlRedireccion!== "") ?
                    <a className="btn btn-primary w-100 mt-5" target="_blank" rel="noreferrer" href={patrocinador.urlRedireccion} role="button">Ver patrocinador</a>
                : null
                } */}
            </div>
        </div>
    </div>
    )
}


export default ContenedorVideoIndividual;