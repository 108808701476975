import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import axios from "axios";


function HeaderAdmin(){
    const navigate = useNavigate();
    const paramsUsuario = useParams(); // obtenemos params para poner en url
    
    function handleLogOut(event){
        // evita el parpadeo predefinido
        event.preventDefault();
        localStorage.clear(); // limpiamos el almacentaiento local.

        axios.post("/logout")
        .then(function(response){
            console.log(response);
            navigate("/");
        });
    }
 
    return(
        <div>
            <nav id="miNavBar" className="navbar navbar-expand-sm navbar-dark" aria-label="Third navbar example" >
                <div className="container-fluid">
                    {/* Imagen */}
                    <a className="navbar-brand" href={"/admin/home/" + paramsUsuario.username } ><img src="/imagenes/marca/clvhorizontalblanco.png" alt="" height="40px" /></a>

                    {/* Botón adaptable */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    
                    <div className="collapse navbar-collapse" id="navbarsExample04">
                        {/* Menú principal */}
                        <ul className="navbar-nav me-auto mb-2 mb-sm-0">
                            <li className="nav-item"><a className="nav-link text-light" href={"/admin/home/" + paramsUsuario.username }>Home</a></li>
                            
                            {/* Dropdown Crear */}
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle text-light" href={"#"} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Crear
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item mi-drowpdown" href={"/admin/crear-estadio/" + paramsUsuario.username + "/1" }>Estadio</a></li>
                                    <li><a className="dropdown-item mi-drowpdown" href={"/admin/crear-equipo/" + paramsUsuario.username + "/1"}>Equipo</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item mi-drowpdown" href={"/admin/crear-torneo/" + paramsUsuario.username + "/1"}>Torneo</a></li>
                                </ul>
                            </li>

                            <li className="nav-item"><a className="nav-link text-light" href={"/admin/crear-patrocinador/" + paramsUsuario.username + "/1" }>Patrocinadores</a></li>
                            <li className="nav-item"><a className="nav-link text-light" href={"/admin/usuarios/" + paramsUsuario.username  + "/1"}>Usuarios</a></li> 
                            <li className="nav-item"><a className="nav-link text-light" href="/ver-analitica" target="_blank" >Ver analítica</a></li>
                        </ul>


                        {/* Segunda parte del menú */}
                        <div className="text-md-end text-center headerFinal">
                            <div>
                                <a href={"/admin/mi-cuenta/" + paramsUsuario.username } className="btn btn-outline-w me-2" role="button" >Mi cuenta</a>
                            </div>
                            <div>
                                <form>
                                {/* <a href="/logout" className="btn btn-primary" role="button" >Cerrar Sesión</a> */}
                                <button onClick={handleLogOut} className="btn btn-primary-w" type="submit">Cerrar Sesión</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
    
}

export default HeaderAdmin;