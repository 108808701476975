import React, {useState, useEffect} from "react";
import SidebarUsuario from '../../partials/sidebarUsuario';
import {useLocation,  useParams, useNavigate, Link} from 'react-router-dom';
import axios from "axios";
import ContenedorVideoIndividual from "../contenedorVideoIndividual";
import "../../estilos/videoIndividual.css";

function VideoIndividualGlobal() {

    const navigate    = useNavigate();
    const location    = useLocation(); // cuando no se envía nada en state, location,state es null
    const routeParams = useParams();
    let {idVideo, nombrePartido} = routeParams;

    const [infoUsuarioLocal, setInfoLocal]= useState({}); // Variables localstorage
    const [avatar, setAvatar] = useState("");
    const [videoMostrar, setVideoMostrar] = useState(null);
    const [videoPatrocinador, setVideoPatrocinador] = useState(null);
    const [posicionVideoPatro, setPosicionVideoPatro] = useState(null);
    const [cargando, setCargando] = useState(true);
    const [patrocinador, setPatrocinador] = useState(null);
    
  // Acceder a la URL anterior desde localStorage
  const previousUrl = localStorage.getItem('urlAnterior');
  const currentUrl = localStorage.getItem('urlActual');
//   console.log( "previus: ", previousUrl);
//   console.log("current: ", currentUrl);
 
    
     
    useEffect(() => {

        async function obtenerVideoIndividual(){
            try {
                await axios.post('/obtener-video-individual', {
                    headers: {'Content-Type': 'multipart/form-data'},
                    params: { idVideo: idVideo  }
                })
                .then(async function(response){
                    setCargando(false);
                    if(response.data.status===200){ 
                        setVideoMostrar(response.data.video); 
                        setPatrocinador(response.data.patrocinador); 
                        setVideoPatrocinador(response.data.videoPatrocinador); 
                        setPosicionVideoPatro(response.data.posicionVideo);
                    }
                    else{
                        navigate("/");
                        window.location.reload();
                        alert(response.data.mensaje);
                    }
                });
            } 
            catch (error) { console.error('Error uploading video', error); }
        }
        obtenerVideoIndividual();

        

       
    }, [location.state, navigate]);
    
    console.log("desde individual_ ", videoPatrocinador);

    return(
        <div className=''>

<div className="d-flex mx-3 flex-column flex-sm-row">

    {/* Columna 1: Banner patrocinador */}
    <div className="col-12 col-sm-3 sticky-container-01 px-5">
        <div className="sticky-content-01">
            {patrocinador && patrocinador.banners[0].urlBanner !== "" ? (
                <div>
                    <h3 className="text-center mb-3">Patrocinado por</h3>
                    <a 
                        href={patrocinador.banners[0].urlRedireccion} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="link-container-01"
                    >
                        <img 
                            className="img-fluid-01 mostrar-en-pequenas mb-5" 
                            src={patrocinador.logo} 
                            alt="Banner Patrocinador" 
                            key={patrocinador.logo + Date.now()} 
                        />
                        <img 
                            className="img-fluid-full-01 ocultar-en-pequenas mb-5" 
                            src={`${patrocinador.banners[0].urlBanner}?t=${Date.now()}`} 
                            alt="Banner Patrocinador" 
                            key={patrocinador.banners[0].urlBanner + Date.now()} 
                        />
                    </a>
                </div>
            ) : (
                <img 
                    className="img-fluid-01 mb-3" 
                    src="/imagenes/marca/clvcazul.png" 
                    alt="Banner Predeterminado" 
                />
            )}
        </div>
    </div>

                {/* Columna 2: Video */}
                <div className="col-12 col-sm-6 container-fluid">
                    {/* Video Contenedor */}

                    



                    {videoMostrar ? (
                        <ContenedorVideoIndividual
                            urlVolver={previousUrl ? previousUrl : false}
                            idCLV={-1}
                            idVideo={idVideo}
                            videoMostrar={videoMostrar}
                            patrocinador={patrocinador && patrocinador.videos[0] ? patrocinador.videos[0] : ""}
                            videoPatrocinador={ (videoPatrocinador) ? videoPatrocinador: null  } 
                            posicionVideoPatro={posicionVideoPatro}
                        />
                    ) : (
                        <p className="text-center my-5">Buscando video</p>
                    )}
                </div>





    {/* Columna 3: Banner patrocinador */}
    <div className="col-12 col-sm-3 sticky-container-01 px-5 ">
        <div className="sticky-content-01">
            {patrocinador && patrocinador.banners[0].urlBanner !== "" ? (
                <div>
                    <h3 className="text-center mb-3">Patrocinado por</h3>
                    <a 
                        href={patrocinador.banners[0].urlRedireccion} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="link-container-01"
                    >
                        <img 
                            className="img-fluid-01 ocultar-en-pequenas mb-5" 
                            src={patrocinador.logo} 
                            alt="Banner Patrocinador" 
                            key={patrocinador.logo + Date.now()} 
                        />
                        <img 
                            className="img-fluid-full-01 mostrar-en-pequenas mb-5" 
                            src={`${patrocinador.banners[0].urlBanner}?t=${Date.now()}`} 
                            alt="Banner Patrocinador" 
                            key={patrocinador.banners[0].urlBanner + Date.now()} 
                        />
                    </a>
                </div>
            ) : (
                <img 
                    className="img-fluid-01 mb-3" 
                    src="/imagenes/marca/clvcazul.png" 
                    alt="Banner Predeterminado" 
                />
            )}
        </div>
    </div>

</div>


            {/* zona cargando */}
            {cargando && (
                <div className="overlay">
                <div className="loader"></div>
                <p className="loadingText">Buscando video, por favor espere...</p>
                </div>
            )}

        </div>
    )
}



export default VideoIndividualGlobal;