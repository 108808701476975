import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate, useParams, useLocation} from "react-router-dom";
import axios from "axios";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../estilos/paginas.css';


function EditarPatrocinador(){
    const navigate = useNavigate();
    const routeParams = useParams();
    const location = useLocation();
    const idPatrocinador = location.state.idPatrocinador;

    

    const [cargando, setCargando]          = useState(false);
    const [patrocinador, setPatrocinador]  = useState(null);
    const [infoUsuarioLocal, setInfoLocal]          = useState({}); // Variables localstorage
    const [torneos, setTorneos]                       = useState([]);
    const [torneoDePatrocinador, setTorneoDePatrocinador] = useState(null);
    const [partidosSinEditar, setPartidosSinEditar] = useState([]);
    const [partidosEditados, setPartidosEditados]   = useState([]);
    const [equipos, setEquipos]                     = useState([]);
    const [estadios, setEstadios]                   = useState([]);
    const [startDate, setStartDate]                 = useState(new Date());
    const [archivo, setArchivo]                     = useState(null); // imagen subida
    const urlVolver   = "/admin/crear-patrocinador/" + routeParams.username + "/1";

    
    
    useEffect(() => {
        
        async function ObtenerDatosDePatrocinador(){
            setCargando(true);
            let datos = { idPatrocinador: idPatrocinador }
            await axios.post("/obtener-datos-patrocinador", datos)
            .then(async function(response){
                setCargando(false);
                if(response.data.status ===200){
                    setPatrocinador(response.data.patrocinador); setTorneoDePatrocinador(response.data.torneo);
                }
                else{
                    navigate(urlVolver);
                    window.location.reload();
                    alert("error al encontrar patrocinador.");
                }
            });
        }
        ObtenerDatosDePatrocinador();


        async function ObtenerTorneosParaEscoger(){
            setCargando(true);
            let datos = { idPatrocinador: idPatrocinador }
            await axios.post("/obtener-torneos-para-escoger", datos)
            .then(async function(response){
                setCargando(false);
                if(response.data.status ===200){
                    setTorneos(response.data.torneos);
                }
                else{
                    navigate(urlVolver);
                    window.location.reload();
                    alert("error al encontrar patrocinador.");
                }
            });
        }
        ObtenerTorneosParaEscoger();




       
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);


   
    
    
    async function funcionEditarPartido(datos){

        
        
        if(datos.idEquipoUno==="" || datos.idEquipoDos==="" || datos.idEstadio===""){
            navigate("/alerta", {state: {titulo:"Ingresar datos", mensaje:"Debes ingresar todos los datos para proceder.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );
        }
        else if(datos.idEquipoUno===datos.idEquipoDos){
            navigate("/alerta", {state: {titulo:"Mismo equipo", mensaje:"Debes escoger equipos diferentes para proceder.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );
        }
        else{
            await axios.post("/editar-partido", datos)
            .then(function(response){
                if(response.data.modificado===true){
                    window.location.reload();
                }
            });
        }
    }

    async function eliminarPartido(datos){
        console.log(datos);

        await axios.post("/eliminar-partido", datos)
        .then(function(response){
            if(response.data.status===200){
                window.location.reload();
                alert("Partido eliminado");
            }
        });
    }

    // FORMULARIO /////////////////////////////
    const[input, setInput] = useState ({
        torneoEscogido: "",
        linkPatrocinador: "",
        linkImagen: "",
        numeroVideo: null,
        fecha: null
    });

    function handleChange(event){
        const {name, value} = event.target;
    
        setInput(prevInput => { return { ...prevInput, [name]: value } });
    }
    const handleChange2 = (e) => {
        const { value, checked } = e.target;
        setInput((prevInput) => {
          const fechasSeleccionadas = new Set(prevInput.fecha); // Utilizamos Set para evitar duplicados
          if (checked) {
            fechasSeleccionadas.add(value); // Añade la fecha seleccionada
          } else {
            fechasSeleccionadas.delete(value); // Elimina la fecha si se desmarca
          }
          return { ...prevInput, fecha: Array.from(fechasSeleccionadas) }; // Actualiza el estado con las fechas seleccionadas
        });
      };
    // FORMULARIO /////////////////////////////
    
    

    function controlCambioVideo(event){
        //console.log(event);
        const archivoSubido = event.target.files[0]; //la imagen está en la posición 0 del arreglo files
        if(archivoSubido){
            setArchivo(archivoSubido);
        }
    }

    async function anadirVideo(event){
        setCargando(true);
        event.preventDefault();

        let linkPatrocinador = input.linkPatrocinador;
        const formData = new FormData(); // Creamos formData para pasar la imagen sin problema.
        formData.append('file', archivo); // guardamos imagen en 'file'

        if(linkPatrocinador==="" || !input.numeroVideo || !archivo || !input.fecha){
            setCargando(false);
            setArchivo(null);
            alert("Debes agregar un link, video, fecha y escoger cual video vas a subir.");
            window.location.reload();
            return
        }

        
        await axios.post("/anadir-video-patrocinador", formData, {
            params: {  idPatrocinador: idPatrocinador, nombrePatrocinador: patrocinador.nombrePatrocinador, linkPatrocinador:  linkPatrocinador, numeroVideo: input.numeroVideo, fechas: input.fecha }
        })
        .then(function(response){
            setCargando(false);

            if(response.data.status ===200){
                window.location.reload();
                alert("Video añadido.");
            }
            else{
                window.location.reload();
                alert("error. Vuelva a intentar");
            }
        });
        
    }
    

    async function funcionEscogerTorneo(event){
        event.preventDefault();
        setCargando(true);
        let idTorneoEscogido = input.torneoEscogido;

        await axios.post("/escoger-torneo-patrocinador", {
            params: {  idTorneo: idTorneoEscogido, idPatrocinador: idPatrocinador}
        })
        .then(function(response){
            setCargando(false);
            if(response.data.status ===200){
                window.location.reload();
                alert("Torneo escogido correctamente.");
            }
            else{
                window.location.reload();
                alert("error. Vuelva a intentar");
            }
        });
        

    }

    async function anadirBanner(event){
        setCargando(true);
        event.preventDefault();
        let linkImagen = input.linkImagen;
        const formData = new FormData(); // Creamos formData para pasar la imagen sin problema.
        formData.append('file', archivo); // guardamos imagen en 'file'

        if(linkImagen===""){
            setCargando(false);
            setArchivo(null);
            window.location.reload();
            alert("Debes agregar un link para este banner.");
        }

        else{
            await axios.post("/anadir-banner-patrocinador", formData, {
                params: {  idPatrocinador: idPatrocinador, nombrePatrocinador: patrocinador.nombrePatrocinador, linkImagen: linkImagen }
            })
            .then(function(response){
                setCargando(false);

                if(response.data.status ===200){
                    window.location.reload();
                    alert("Banner añadido añadido.");
                }
                else{
                    window.location.reload();
                    alert("error. Vuelva a intentar");
                }
            });
        }
    }
    
    
    async function anadirLogoAvideos(event){
        setCargando(true);
        event.preventDefault();

        await axios.post("/anadir-logo-a-videos", {
            params: {  idPatrocinador: idPatrocinador }
        })
        .then(function(response){
            setCargando(false);

            if(response.data.status === 200){
                window.location.reload();
                alert("Banner añadido añadido.");
            }
            else{
                window.location.reload();
                alert(response.data.mensaje);
            }
        });
        
    }

    console.log(input.fecha);
     
    return(
        <div className='contenedorPaginasUsuario'>
            


            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">Editar patrocinador</h1>
                    </div>
            

                     
                 

                    {(patrocinador)?
                    <div className='container'>

                        {/* Contenedor información */}
                        <div className="p-5 my-4 bg-body-tertiary rounded-3">
                            <div className="row  d-flex align-items-center" style={{ minHeight: "200px" }}>
                                {/* columna 1 */}
                                <div className="col-lg-6 d-flex justify-content-center">
                                        <div className="d-flex justify-content-center"> <img className="imagenPrincipal" src={patrocinador.logo}  alt="" /></div>
                                </div>

                                {/* Columna 2 */}
                                <div className="col-lg-6 d-flex justify-content-center">
                                    <div className="container-fluid py-5">
                                        <h2 className="">Patrocinador: {patrocinador.nombrePatrocinador}</h2>
                                        {/* <p className="col-md-8">Descripción: {patrocinador.texto}</p> */}
                                    </div>
                                </div>
                            </div>


                            
                            
                           
                        </div>

                        {/* Escoger torneo */}
                        <div className="p-5 my-4 bg-body-tertiary rounded-3">
                            <h2 className="text-center" >1. Escoger torneo</h2>
                            <p className="text-center">El primer paso será escoger en cuál torneo quieres mostrar los anuncios de este patrocinador. Los anuncios se mostrarán en todos los videos del torneo.</p>
                            {/* Input escoger torneo */}
                            <form>

                                <div className="form-floating">
                                    <select onChange={handleChange} value={input.torneoEscogido} name="torneoEscogido"  className="form-select" id="floatingSelect" aria-label="Floating label select example">
                                        <option defaultValue={"f"}>Escoger torneo</option>
                                        {torneos.map(actual => (
                                            <option key={actual.idTorneo} value={actual.idTorneo}>{actual.nombre}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="floatingSelect">Escoger torneo</label>
                                </div>

                                <button disabled={torneoDePatrocinador ? true : false} className="w-100 btn btn-dark mt-3" onClick={funcionEscogerTorneo}>
                                    {torneoDePatrocinador ? "Ya escogiste un torneo para este patrocinador" : "Escoger Torneo"}
                                </button>


                            </form>

                            {/* Si ya tiene torneo entonces mostramos aquí */}
                            {(torneoDePatrocinador) ?
                            <div>
                                <h3 className="text-center mt-4">Torneo de este patrocinador: {torneoDePatrocinador.nombre} </h3>
                            </div>
                            :null}
                        </div>

                        
                       { (torneoDePatrocinador) ? 
                       <div>
                            {/* Subir video */}
                            <div className="p-5 my-4 bg-body-tertiary rounded-3">
                            <h2 className="text-center" >2. Subir video</h2>
                                <p className="text-center">Sube el video que se mostrará previamente el video del partido. Adicional, sube el link de redirección de este video hacia el contenido del cliente.</p>
                                {/* Input video */}
                                <form>

                                    {/* link */}
                                    <div className="form-floating mb-3">
                                        <input onChange={handleChange} value={input.linkPatrocinador} name="linkPatrocinador" type="text" className="form-control" id="floatingInput" placeholder="linkPatrocinador"  title="Ingresa un link." />
                                        <label htmlFor="floatingInput">Link</label>
                                    </div>

                                    {/* Dropdown cual video */}
                                    <div className="form-floating mb-3">
                                        <select onChange={handleChange} value={input.numeroVideo} name="numeroVideo"  className="form-select" id="floatingSelect" aria-label="Floating label select example">
                                            <option defaultValue={null}>Escoger Video</option>
                                            <option  value={0}>Video largo</option>
                                            <option  value={1}>Video Corto</option>
                                        </select>
                                        <label htmlFor="floatingSelect">Escoger torneo</label>
                                    </div>

                                    {/* Dropdown cual fecha */}
                                    <h6>Fechas</h6>
                                    <p>Escoge en cuál fecha quieres que este video se muestre</p>
                                    <div>
                                        {Array.from({ length: torneoDePatrocinador.cantidadDeFechas }, (_, index) => (
                                            <div className="form-check form-check-inline" key={index + 1}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`inlineCheckbox${index + 1}`}
                                                value={index + 1}
                                                onChange={handleChange2} // Función para manejar el cambio de selección
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor={`inlineCheckbox${index + 1}`}
                                            >
                                                {index + 1}
                                            </label>
                                            </div>
                                        ))}
                                    </div>





                                    {/* Input video */}
                                    <div className="form-floating my-3">
                                        <input onChange={controlCambioVideo} className="form-control" type="file" id="formFile" /> 
                                        <label htmlFor="floatingInput">Video</label>
                                        <button onClick={anadirVideo} type="submit" style={{width: "100%"}} className="btn btn-primary mt-3">Añadir video</button>
                                    </div>
                                </form>

                                        
                                {/* Video existente */}
                                <h4 className="text-center mt-5 mb-3">Videos actuales</h4>

                                {/* video largo */}
                                {(patrocinador.videos[0].urlVideo !== "")?
                                <div>
                                    <p> <strong>Video largo:</strong> duración que desee el patrocinador.</p>
                                    <p><strong>Fechas:</strong> {patrocinador.videos[0].fechas.join(", ")}</p> {/* Muestra las fechas separadas por comas */}
                                    <video
                                        className="video-js vjs-default-skin videoIndividual"
                                        preload="auto"
                                        controls
                                        data-setup="{}"
                                        style={{ height: "200px"}}
                                        onError={(e) => console.error("Error al cargar el video:", e)}
                                    >
                                        <source src={patrocinador.videos[0].urlVideo} type="video/mp4" />
                                    </video>
                                    <p className="text-center mt-3"> <strong>link:</strong> {patrocinador.videos[0].urlRedireccion}</p>
                                </div> 
                                : null}
                                

                                 {/* video corto */}
                                 {( patrocinador.videos[1] &&  patrocinador.videos[1].urlVideo !== "")?
                                <div>
                                    <p> <strong>Video corto:</strong> duración corta de 3 segundos.</p>
                                    <p><strong>Fechas:</strong> {patrocinador.videos[1].fechas.join(", ")}</p> {/* Muestra las fechas separadas por comas */}
                                    <video
                                        className="video-js vjs-default-skin videoIndividual"
                                        preload="auto"
                                        controls
                                        data-setup="{}"
                                        style={{ height: "200px"}}
                                        onError={(e) => console.error("Error al cargar el video:", e)}
                                    >
                                        <source src={patrocinador.videos[1].urlVideo} type="video/mp4" />
                                    </video>
                                    <p className="text-center mt-3"> <strong>link:</strong> {patrocinador.videos[1].urlRedireccion}</p>
                                </div> 
                                : null}
                            </div>
                                




                            {/* Subir banner */}
                            <div className="p-5 my-4 bg-body-tertiary rounded-3">
                            <h2 className="text-center" >3. Subir banner</h2>
                                <p className="text-center">Sube el banner del patrocinador que se verá en todos los videos del torneo.</p>
                                {/* Input video */}
                                <form>

                                    {/* link */}
                                    <div className="form-floating mb-3">
                                        <input onChange={handleChange} value={input.linkImagen} name="linkImagen" type="text" className="form-control" id="floatingInput" placeholder="linkImagen"  title="Ingresa un link." />
                                        <label htmlFor="floatingInput">Link</label>
                                    </div>

                                    <div className="form-floating my-3">
                                        <input onChange={controlCambioVideo} className="form-control" type="file" id="formFile" /> 
                                        <label htmlFor="floatingInput">Banner</label>
                                        <button onClick={anadirBanner} type="submit" style={{width: "100%"}} className="btn btn-primary mt-3">Añadir banner</button>
                                    </div>
                                </form>

                                {/* Video existente */}
                                {(patrocinador.banners[0].urlBanner !== "")?
                            <div>
                                        <h4 className="text-center mt-5 mb-3">Banner y link actual</h4>
                                        <img style={{width: "100%"}} src={patrocinador.banners[0].urlBanner} ></img>
                                    <p className="text-center mt-3"> <strong>link:</strong> {patrocinador.banners[0].urlRedireccion}</p>

                            </div> 
                                : null}
                            </div>




                            {/* Subir log a todos los videos */}
                            <div className="p-5 my-4 bg-body-tertiary rounded-3">
                                <h2 className="text-center" >4. Subir logo</h2>
                                <p className="text-center">Oprime este botón solo estás completamente seguro de subir el logo <strong>sin fondo</strong> a todos los videos de este torneo en la parte inferior derecha .
                                El logo que se usará es el logo de este patrocinador. Si deseas cambiarlo, ve atrás y cambia el logo antes de proceder. <strong>Solo oprimir una vez y la función iniciará en segundo plano.</strong>
                                </p>

                                    

                                <h5 className="text-center mt-5 mb-3" >Especificaciones</h5>
                                <ul className="text-center" >
                                    <li> <strong>Tamaño:</strong> 500px x 500px</li>
                                    <li> <strong>Tipo:</strong> png, sin fondo obligatoriamente</li>
                                </ul>

                                <button onClick={anadirLogoAvideos} type="submit" style={{width: "100%"}} className="btn btn-warning mt-3">Añadir Logo a videos</button>
                                
                            </div>
                        </div>
                        : <p>Debes escoger un torneo primero para continuar.</p> 
                        }







                    </div>
                    : null
                    }



                


                    {/* zona cargando */}
                    {cargando && (
                        <div className="overlay">
                        <div className="loader"></div>
                        <p className="loadingText">Completando proceso, por favor espere...</p>
                        </div>
                    )}

                </div>
            </div>

            
        </div>
    )
}

export default EditarPatrocinador;