import '../../estilos/paginas.css';
import { useEffect } from 'react';

function Contacto() {
    
    // Modificar la clase de body par página de registro - styles.css /////////
    useEffect(() => {
        document.body.classList.add('claseBodyWeb')
        return () => {
        document.body.classList.remove('claseBodyWeb')
        }
    }, []);
    // Modificar la clase de body par página de registro - styles.css /////////



    return(
        <div>
            {/* Primer contenedor: imagen principal */}
            <div className='contenedorContactoPagina text-light'>
                    <h1>Contacto</h1>
                    <p className='subContenedorPrincipal'>Póngase en contacto con nosotros</p>
            </div>



            <div className='container my-5'>

            <h2>Escríbenos</h2>
            <p>Ponemos a tu disposición nuestros medios de contacto.</p>

            {/* Íconos */}
            <div class="my-5"> 
                <ul class="list-group list-group-flush">
                {/* Facebook */}
                <li class="list-group-item">
                    <a href="https://www.facebook.com/profile.php?id=61566444787956 " style={{textDecoration: "none"}} class="link-dark" target="_blank" rel="noreferrer">
                        <i class="bi bi-facebook" style={{fontSize: "18px"}}>&nbsp; Facebook</i>
                    </a>
                </li>
                    {/* Wapp */}
                <li class="list-group-item">
                    <a href="https://wa.me/573132957130 " style={{textDecoration: "none"}} class="link-dark" target="_blank" rel="noreferrer">
                        <i class="bi bi-whatsapp" style={{fontSize: "18px"}}>&nbsp; +57 313 295 7130</i>
                    </a>
                </li>
                {/* Youtube */}
                <li class="list-group-item">
                    <a href="https://youtube.com/@oficialcomolavio " style={{textDecoration: "none"}} class="link-dark" target="_blank" rel="noreferrer">
                        <i class="bi bi-youtube" style={{fontSize: "18px"}}>&nbsp; Youtube</i>
                    </a>
                </li>
                {/* TikTok */}
                <li class="list-group-item">
                    <a href="https://www.tiktok.com/@comolavio " style={{textDecoration: "none"}} class="link-dark" target="_blank" rel="noreferrer">
                        <i class="bi bi-tiktok" style={{fontSize: "18px"}}>&nbsp; TikTok</i>
                    </a>
                </li>
                {/* Correo */}
                <li class="list-group-item">
                    <a href="mailto:contacto@comolavio.com" style={{textDecoration: "none"}} class="link-dark">
                        <i class="bi bi-envelope-fill" style={{fontSize: "18px"}}>&nbsp; contacto@comolavio.com</i>
                    </a>
                </li>
                {/* Dirección */}
                <li class="list-group-item"> <i class="bi bi-geo-alt-fill" style={{fontSize: "18px", color: "black"}}>&nbsp;Cll. 20 #82 - 52, Bogotá.</i></li>
                </ul>
            </div>
             
            {/* Formulario e imagen */}
            <div class="row d-flex align-items-center">   
                
                <div class="col-lg-6">
                    <h3>Deja tu información</h3>
                    <p class="mb-5">Llena el formulario a continuación y en breve nuestro equipo se pondrá en contacto contigo.</p>
                    {/* Formulario */}
                    <form action="/afiliate-ahora" method="post">
                        {/* Nombre y apellido */}
                        <div class="row my-2">
                            <div class="col">
                                <label for="exampleFormControlTextarea1">Nombre</label>
                                <input type="text" class="form-control" placeholder="Nombre" name="fname" required />
                            </div>
                            <div class="col">
                                <label for="exampleFormControlTextarea1">Apellido</label>
                                <input type="text" class="form-control" placeholder="Apellido" name="lname" required />
                            </div>
                        </div>


                        {/* Correo y teléfono */}
                        <div class="row my-2">
                            <div class="col">
                                <label for="exampleInputEmail1">Correo electrónico</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" name="email" required />
                            </div>
                            <div class="col">
                                <label for="exampleFormControlTextarea1">Teléfono</label>
                                <input type="number" class="form-control" placeholder="telefono" name="phone" required />
                            </div>
                        </div>


                        {/* Mensaje */}
                        <div class="form-group my-2">
                            <label for="exampleFormControlTextarea1">Mensaje</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name="mensaje" required></textarea>
                        </div>
                        {/* Botón */}
                        <button type="submit" class="btn btn-primary mt-4 py-2" style={{width: "100%"}}>Enviar Mensaje</button>
            
                    </form>
                    
                </div>

                {/* Imagen */}
                <div class="col-lg-6 d-flex justify-content-center">
                    <img src="/imagenes/webGlobal/contactoform.jpg" alt="contacto" class="imgVertical" />
                </div>
            </div>


            {/* Google maps */}
            <div class="container" style={{marginTop: "80px", marginBottom: "30px"}} >
                <h4>Visítanos</h4>
                <p>Aquí dejamos nuestra ubicación para que puedas visitarnos!</p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d991.545024295735!2d-75.59199163032017!3d6.239979132768539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e4429a4a1f58561%3A0x40a8d31ca326ab95!2sTv.%2039A%20%2370a%20-%2016%2C%20Laureles%20-%20Estadio%2C%20Medell%C3%ADn%2C%20Laureles%2C%20Medell%C3%ADn%2C%20Antioquia%2C%20Colombia!5e0!3m2!1ses-419!2sus!4v1706248736826!5m2!1ses-419!2sus" style={{border: "0"}} width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            </div>



        </div>
    )
}

export default Contacto;